var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7d1c0e4576c74552e09315da928f708015200651"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import Config from './src/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_RELEASE =
  process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE;

Sentry.init({
  environment: Config.ENV,
  dsn:
    SENTRY_DSN ||
    'https://38cf47b81cc842ebb4733c2afb9e129b@o266353.ingest.sentry.io/1493410',
  tracesSampleRate: 0.2,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click') {
      const { target } = hint.event;

      if (target.ariaLabel) {
        breadcrumb.message = target.ariaLabel;
      } else if (target.innerText) {
        breadcrumb.message = target.innerText;
      }
    }
    return breadcrumb;
  },
  autoSessionTracking: true,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured'
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: true
    }),
    // Anything deeper than {depth} levels will be shown as [Object], [Array], [Function]
    // 3 is the default, can be tweaked if needed
    new ExtraErrorDataIntegration({ depth: 3 })
  ],
  release: SENTRY_RELEASE
});
